.Wrapper{
    display: flex;
    justify-content: center;
    padding-top: 100px;
}
.contain {
    max-width: 350px;
    background: #F8F9FD;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
    border-radius: 40px;
    padding: 25px 35px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .heading1 {
    text-align: center;
    font-weight: 900;
    font-size: 20px;
    color:#40547A;
  }

  
  .LogButton {
    position: absolute;
    right: 47px;
    top: 22px;
    
  }
  .LogButton button{
  
    background-color: rgb(175, 59, 5);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    
  }
  
  
  .form {
    margin-top: 20px;
  }
  
   .input {
    width: 90%;
    background: white;
    border: none;
    padding: 15px 20px;
    border-radius: 20px;
    margin-top: 15px;
    box-shadow: #cff0ff 0px 10px 10px -5px;
    border-inline: 2px solid transparent;
    
  }
  

  
  .form .login-button {
    display: block;
    width: 100%;
    font-weight: bold;
    background: linear-gradient(45deg, #40547A 0%, #40547A 100%);
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: #8d9ab2 0px 20px 10px -15px;
    border: none;
    transition: all 0.2s ease-in-out;
  }
  
  .form .login-button:hover {
    transform: scale(1.03);
    box-shadow: #99b2e1 0px 23px 10px -20px;
  }
  
  .form .login-button:active {
    transform: scale(0.95);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
  }
  
