.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  height: 100px;
  backdrop-filter: blur(10px);
}

.logo img {
  width: 80px;
}
.menu-icon img {
  width: 40px;
}

.menu-icon {
  display: none;
}



.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.NavLink {
  color: #e6595a;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 700;
  position: relative;
}

/* .nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
} */

@media only screen and (max-width: 430px) {
  .navbar{
    height: 70px;
    align-items: center;
  }
  .logo img{
    width: 45px;
  }
  .menu-icon img{
    width: 35px;
  }
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .NavLink{
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    width: 50px;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #ffffffc9;
    width: 0px;
    /* height: calc(100vh - 60px); */
    height: 250px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 200px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 55px;
  }
 
  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    font-size: 16px;
  }
}
