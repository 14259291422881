
.quotation_wrapper {
  height: 570px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.quotation_form {
  height: 530px;
  width: 550px;
  background: #ffffff;
  filter: drop-shadow(1px 1px 5px rgb(174, 174, 174));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  flex-direction: column;
}

.quotation_form h1 {
  font-size: 45px;
  padding-bottom: 20px;
}
.quotation_form select {
  height: 35px;
  width: 400px;
  border-radius: 7px;
}
.quotation_form input {
  height: 35px;
  width: 400px;
  border: 1px solid black;
  border-radius: 7px;
  padding-left: 7px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.quotation_form button {
  height: 45px;
  width: 250px;
  border-radius: 10px;
  outline: none;
  background-color: #362e56;
  color: white;
  font-size: 25px;
}
.quotation_form textarea {
  width: 100%;
}
.quotation_form_button {
  display: flex;
  justify-content: center;
}
.from_wreapper {
  display: flex;
  gap: 25px;
}
.from_wreapper #location {
  width: 184px;
}
.from_wreapper #tolocation {
  width: 184px;
}
.error_div{
  display: flex;
  flex-direction: column;
}
form span{
  color: red;
}
@media only screen and (max-width: 430px) {
  .quotation_form {
    height: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
  }
  .quotation_wrapper{
    height: 388px;
  }
  .quotation_form h1 {
    font-size: 25px;
  }

  .quotation_form select {
    height: 28px;
    width: 100%;
  }
  .quotation_form input {
    width: 100%;
    height: 28px;
    border-radius: 6px;
    padding-left: 7px;
  }

  .quotation_form button {
    width: 72%;
    height: 35px;
    font-size: 20px;
  }
  .quotation_form span{
    font-size: 21px;
  }
  form {
    width: 100%;
    gap: 10px;
  }
  .quotation_form textarea {
    width: 100%;
    height: 35px;
    text-align: center;
  }
  .from_wreapper {
    flex-direction: column;
  }
  .from_wreapper #location {
    width: 100%;
  }
  .from_wreapper #tolocation {
    width: 100%;
  }
}
@media (min-width: 429px) and (max-width: 768px) {
  .quotation_form {
    height: auto;
    width: 60%;
    padding: 14px;
  }
  .quotation_form h1 {
    font-size: 40px;
  }

  form {
    width: 100%;
    gap: 10px;
  }
  .quotation_form select {
    height: 35px;
    width: 100%;
  }
  .quotation_form input {
    width: 100%;
    height: 35px;
  }

  .quotation_form textarea {
    width: 100%;
    height: 47px;
    text-align: center;
  }
  .quotation_form button {
    width: 72%;
    height: 47px;
    font-size: 20px;
  }
  .from_wreapper {
    flex-direction: column;
  }
  .from_wreapper #location {
    width: 100%;
  }
  .from_wreapper #tolocation {
    width: 100%;
  }
}
