

.wrap{
  display: flex;

  justify-content: center;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
}


td, th {
  border: 1px solid #40547A;
  padding: 10px;
  text-align: left;
  
}
.inputWrapper{
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.inputWrapper input{
  height: 35px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #40547A;
}

.showWrapper h1{
  font-family: Arial, Helvetica, sans-serif;
  color: #051431 ;
  padding-bottom: 10px;
  text-align: center;
}

 .btnWrapper{
  display: flex;
  justify-content: right;
  position: absolute;
  right: 50px;
 } 
 .btnWrapper button{
  padding: 6px;
  border-radius: 4px;
  background-color: rgb(249, 17, 17);
  color: white;
  border: none;
  font-weight: 700;
 }



@media  screen and ( max-width: 425px) {

  .showWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .showWrapper h1{
    font-family: Arial, Helvetica, sans-serif;
    color: #051431 ;
    padding-bottom: 10px;
  }
  .inputWrapper{
    display: flex;
    
    justify-content: center;
    padding: 5px;
    padding-left: 10px
  
  }
  .inputWrapper input{
    height: 25px;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #40547A;
  }
  
  .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 70%;
  }
  td, th {
   font-size: 9px;
    padding: 1px;
    
    
  }
  .btnWrapper{
    display: flex;
    justify-content: right;
    position: absolute;
    right: 30px;
   } 

   
  }
  @media screen and ((min-width:425px) and (max-width: 768px)){
   
    .wrap{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 90%;
    }
    td, th {
     font-size: 10px;
      padding: 5px;
      
      
    }
  }
