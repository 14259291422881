.SlideWapper{
    height: 80vh;
    overflow: hidden;
   background-image: url(../../Assets/Group\ 23.png);
}

.slides{
    width: auto;
    height: 80vh;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    align-items: center;
    justify-content: start;
}
.SlideWapper h1 {
    text-align: center;
    color: #505f82;
    text-decoration: underline;
  }
  .slide img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
@media only screen and (max-width: 430px) {
    .slide{
        align-items: center;
        justify-content: center;
    }
    .slides img{
        width: 90%;
    }
    .SlideWapper h1{
        font-size: 32px;
    }
}


.slide{
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
   color: white;
}
.slide:hover{
    /* filter: brightness(100%);  */
    scale: 1.05;
    transition: .1s linear;
}



/* .slide :nth-child(1){
    background-color: red;
} */

/* .slide1{
  background-color: aliceblue;
}
.slide2{
    background-color: aqua;
}
.slide3{
    background-color: aquamarine;
} */